/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, Fragment } from "react";

const Checkout = () => {
  // getSnippet(/* Your backend endpoint URL */).then(function renderSnippet(htmlSnippet) {
  //     var checkoutContainer = document.getElementById('my-checkout-container')
  //     checkoutContainer.innerHTML = htmlSnippet
  //     var scriptsTags = checkoutContainer.getElementsByTagName('script')
  //     // This is necessary otherwise the scripts tags are not going to be evaluated
  //     for (var i = 0; i < scriptsTags.length; i++) {
  //         var parentNode = scriptsTags[i].parentNode
  //         var newScriptTag = document.createElement('script')
  //         newScriptTag.type = 'text/javascript'
  //         newScriptTag.text = scriptsTags[i].text
  //         parentNode.removeChild(scriptsTags[i])
  //         parentNode.appendChild(newScriptTag)
  //     }
  //   }).catch(renderError)
  return (
    <div sx={{ display: "flex" }}>
      <section sx={{ flex: 1 }}>hey hey</section>
      <section sx={{ flex: 1 }}>{/* <div id="my-checkout" /> */}</section>
    </div>
  );
};

export default Checkout;
